<template>
    <el-form-item :label="$t('calendar.services')">
        <el-select
            v-model="selectedServices"
            :placeholder="$t('calendar.services')"
            multiple
            filterable
            class="dialog-select"
            popper-class="longer-select"
            @change="onChange"
        >
            <el-option-group
                v-for="(services, category) in servicesData"
                :key="category"
                :label="category"
            >
                <el-option
                    v-for="service in services"
                    :key="service.uuid"
                    :label="service.name"
                    :value="service.uuid"
                />
            </el-option-group>
        </el-select>
    </el-form-item>
</template>

<script>
export default {
    props: {
        selectedServicesProp: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            selectedServices:           this.selectedServicesProp,
            servicesData: [],
        };
    },

    created() {
        this.getServices();
    },

    methods: {
        async getServices() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/services/with_categories`);
            this.servicesData = data;
        },

        onChange() {
            this.$emit('change', this.selectedServices);
        },
    },
};
</script>
